@import '../../breakpoint.scss';
.teamSection {
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  color: #f2f2f2;
  font-family: 'Philosopher', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  .teamContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 0 3rem;
    @include media('<=phone') {
      padding: 0;
    }
    @include media('<=tablet') {
      padding: 100px 0;
    }
    .teamTitle {
     
        font-size: 2.5rem;
        line-height: 2.5rem;
        background: -webkit-linear-gradient(180deg, #ffc700 0%, #ff8a00 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      
      @include media('<=tablet') {
        display: block;
      }
    }
    .outerCardContainer {
      .cardContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin: 6rem 1.5rem 0rem 1.5rem;
        width: 300px;
        height: 170px;
        @include media('<=tablet') {
          margin: 3rem 1rem 0rem 1rem;
          width: 200px;
          height: 130px;
        }
        @include media('<=phone') {
          margin: 0.5rem;
          width: 150px;
          height: 170px;
        }
        .imgContainer {
          width: 150px;
          height: 150px;
          user-select: none;
          pointer-events: none;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
          @include media('<=1450px') {
            width: 100px;
            height: 100px;
          }
          @include media('<=tablet') {
            width: 80px;
            height: 80px;
          }
          @include media('<=tablet') {
            width: 60px;
            height: 60px;
          }
        }
        .textContainer {
          // width: 170px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          padding-top: 1.4rem;
          .name {
            h1 {
              font-size: 1.2rem;
            }
          }
          .role {
            h1 {
              text-align: center;
              font-size: 1.5rem;
              font-weight: bolder;
              color: #ff8a00;
            }
          }
          @include media('<=1450px') {
            padding-top: 1rem;
            .name {
              h1 {
                font-size: 0.9rem;
              }
            }
            .role {
              h1 {
                font-size: 1.2rem;
              }
            }
          }
          @include media('<=tablet') {
            padding-top: 1rem;
            width: 220px;
            .role {
              h1 {
                font-size: 1rem;
              }
            }
          }
          @include media('<=phone') {
            padding-top: 1rem;
            width: 120px;
            .role {
              h1 {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
}
