@import url('https://fonts.googleapis.com/css2?family=Philosopher:wght@400;700&display=swap');

@import './breakpoint.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// custom scrollbar
html {
  --scrollbarBG: #ffe4c4;
  --thumbBG: #ffa600ef;
}
body::-webkit-scrollbar {
  width: 0.8rem;
}
body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  scrollbar-color: var(--thumbBG);
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

// Selection resets
::-moz-selection {
  /* Code for Firefox */
  color: whitesmoke;
  background: #ff8a00;
}

::selection {
  color: whitesmoke;
  background: #ff8a00;
}

html,
body {
  overflow-x: hidden;
  font-family: 'Philosopher', sans-serif;
}
.main {
  .innerContainer {
    background: linear-gradient(
      to right top,
      #000000 0%,
      rgba(0, 0, 0, 0) 75.56%
    );
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.innerContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.innerContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

// -> tap color
* {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
