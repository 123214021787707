@import '../../breakpoint.scss';

.heroSection {
  width: 100%;
  height: 100vh;
  // overflow: hidden;
  position: relative;
  color: #f2f2f2;
  font-family: 'Philosopher', sans-serif;

  .innerHeroContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 6rem;
    .scrollDown {
      // position: relative;
      .textContainer {
        position: absolute;
        left: 50%;
        bottom: 9.5%;
        @include media('<=phone') {
          bottom: 11.5%;
        }
        transform: translateX(-50%);
        h1 {
          font-size: 1.5rem;
          font-weight: normal;
          @include media('<=phone') {
            font-size: 1.2rem;
          }
        }
      }
      a {
        cursor: pointer;
        .arrow,
        .arrow:before {
          position: absolute;
          left: 50%;
        }
        .arrow {
          width: 25px;
          height: 25px;
          bottom: 5%;
          @include media('<=phone') {
            bottom: 7%;
          }
          margin: -20px 0 0 -20px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          border-left: none;
          border-top: none;
          border-right: 2px #fff solid;
          border-bottom: 2px #fff solid;
          @include media('<=tablet') {
            width: 19px;
            height: 19px;
          }
        }

        .arrow:before {
          content: '';
          width: 10px;
          height: 10px;
          top: 50%;
          margin: -10px 0 0 -10px;
          border-left: none;
          border-top: none;
          border-right: 1px #fff solid;
          border-bottom: 1px #fff solid;
          animation-duration: 2s;
          animation-iteration-count: infinite;
          animation-name: arrow;
          @include media('<=tablet') {
            width: 8px;
            height: 8px;
          }
        }

        @keyframes arrow {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
            transform: translate(-8px, -8px);
          }
        }
      }
    }
  }
}
