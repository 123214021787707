@import "../../breakpoint.scss";
.investorSection {
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  color: #f2f2f2;
  font-family: "Philosopher", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .leftChar {
    height: 100%;
    max-height: 100vh;
    bottom: -40px;
    left: -100px;
    position: absolute;
    z-index: 1;
    transform: rotate(4deg);
    user-select: none;
    pointer-events: none;
    img {
      width: 100%;
      height: 100%;
      max-height: 100vh;
    }
    @include media("<1400px") {
      height: 65%;
    }
    @include media("<=tablet") {
      display: none;
    }
    @include media("<=phone") {
      display: none;
    }
  }
  .rightChar {
    height: 100%;
    max-height: 100vh;
    bottom: -40px;
    right: -150px;
    transform: rotate(-10deg);
    position: absolute;
    z-index: 1;
    user-select: none;
    pointer-events: none;
    img {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      width: 100%;
      height: 100%;
      max-height: 100vh;
    }
    @include media("<1400px") {
      height: 65%;
    }
    @include media("<=tablet") {
      display: none;
    }
    @include media("<=phone") {
      // height: 53%;
      // bottom: -60px;
      // right: -50px;
      display: none;
    }
  }
  .investorsTitle {
    font-size: 2.5rem;
    line-height: 2.5rem;
    background: -webkit-linear-gradient(180deg, #ffc700 0%, #ff8a00 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2rem;
    @include media("<=tablet") {
      display: block;
    }
  }
  .investorContainer {
    max-width: 420px;
    max-height: 160px;
    @include media("<1400px") {
      max-width: 150px;
      max-height: 70px;
      img {
        max-height: 70px;
        width: auto;
      }
    }
    @include media("<=tablet") {
      max-width: 120px;
      max-height: 50px;
      img {
        max-height: 50px;
      }
    }
    img {
      user-select: none;
      max-height: 100px;
      height: 100%;
    }
  }
}
