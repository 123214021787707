@import '../../../breakpoint.scss';

.marketplaceHeader {
  width: 100%;
  height: 10vh;
  z-index: 10;
  overflow: hidden;
  .nav-main {
    width: 100%;
    font-family: 'Philosopher', sans-serif;
    text-transform: capitalize;
    color: #fff;
    line-height: 22.5px;
    height: 10vh;
    display: grid;
    place-items: center;
    background: rgba($color: #000, $alpha: 0.5);
    .logo {
      cursor: pointer;
      width: 190px;
      margin-top: -4px;
      @include media('<=1300px') {
        width: 140px;
      }
      @include media('<=phone') {
        width: 170px;
      }
    }

    .nav-menu {
      display: flex;
      text-align: center;
      z-index: 10;
      .nav-item {
        a {
          span {
            font-size: 1.2rem;

            @include media('<=1600px') {
              font-size: 1.1rem;
            }

            @include media('<=1450px') {
              font-size: 0.9rem;
            }
            @include media('<=1024') {
              font-size: 1.2rem;
            }
          }
        }
        .active {
          color: #ff8a00;
          border-bottom: 3px solid #278d33;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        .nav-links:hover {
          color: #ff8a00;
          border-bottom: 3px solid #278d33;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
    @include media('<=tablet') {
      .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 10vh;
        left: -110%;
        opacity: 1;
        background: rgba($color: #000, $alpha: 0.5);
        z-index: 10;
      }
      .nav-menu.active {
        left: 0px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
      }
      .nav-item .active {
        color: #ff8a00;
        border: none;
      }
    }
  }
  // wallet button
  .smallScreenWallet {
    display: block;
    @include media('>=770px') {
      display: none;
    }
  }
  .bigScreenWallet {
    display: none;
    @include media('>=770px') {
      display: block;
    }
  }

  .btn-secondary {
    display: flex;
    cursor: pointer;
    border-radius: 9999px;
    border-width: 2px;
    padding: 0.5rem 0.75rem;

    &:hover {
      color: #fff;
      background: #ff8a00;
    }
    @media (min-width: 768px) {
      padding: 1.5rem;
      padding-top: 0.875rem;
      padding-bottom: 0.875rem;
    }
  }
  .btn-secondary.sm {
    padding: 0.5rem 1.5rem;
    &:hover {
      color: #fff;
      background: #ff8a00;
    }

    @include media('<=tablet') {
      padding: 0.35rem 1.3rem;
      margin-bottom: 1rem;
    }
  }
  svg {
    display: block;
  }

  svg {
    fill: #fff;
  }
  svg:hover {
    fill: #ff8a00;
  }

  .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .icon.size-auto svg {
    width: 100%;
    height: 100%;
  }

  .icon svg {
    width: 1rem;
    height: 1rem;
  }

  .icon.md svg {
    width: 2rem;
    height: 2rem;
    @include media('<=1300px') {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
