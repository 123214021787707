@import '../../../breakpoint.scss';

.cardsSection {
  width: 100%;
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f2f2f2;
  font-family: 'Philosopher', sans-serif;
  .cardContainer {
    margin: 3rem 4rem;
    width: 200px;
    @include media('<=tablet') {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 2.5rem 4rem;
    }
    .cardImg {
      width: 200px;
      height: 310px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .cardTitle {
      margin: 1.1rem 0;
      h1 {
        font-size: 1.3rem;
        font-weight: bolder;
      }
    }
    .commingSoon {
      button {
        border: 3px solid #ff8a00;
        padding: 4px;
        color: #ff8a00;
        font-size: 1rem;
        font-weight: bold;
        transition: all ease-in 0.6s;
        &:hover {
          color: #f2f2f2;
          background: #ff8a00;
        }
      }
    }
  }
}
