@import '../../breakpoint.scss';

header {
  width: 100%;
  .nav-main {
    position: absolute;
    width: 100%;
    font-family: 'Philosopher', sans-serif;
    text-transform: capitalize;
    color: #fff;
    line-height: 22.5px;
    height: 15vh;
    display: grid;
    place-items: center;
    @include media('<=tablet') {
      background: rgba($color: #000000, $alpha: 0.5);
    }
    z-index: inherit;
    .logo {
      cursor: pointer;
      width: 200px;
      margin-top: -15px;
      margin-left: -22px;
      @include media('<=1300px') {
        width: 140px;
      }
      @include media('<=phone') {
        width: 170px;
      }
    }

    .nav-menu {
      display: flex;
      text-align: center;

      .nav-item {
        @include media('<=1600px') {
          .p-3 {
            padding: 10px;
          }
        }
        @include media('<=1300px') {
          .p-3 {
            padding: 10px;
          }
          @include media('<=1024px') {
            .p-3 {
              padding: 10px;
            }
          }
        }

        a {
          span {
            font-size: 1.2rem;

            @include media('<=1600px') {
              font-size: 1.1rem;
            }

            @include media('<=1450px') {
              font-size: 0.9rem;
            }
            @include media('<=1024') {
              font-size: 1.2rem;
            }
          }
        }
        .active {
          color: #ff8a00;
          border-bottom: 3px solid #278d33;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        .nav-links:hover {
          color: #ff8a00;
          border-bottom: 3px solid #278d33;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
    .nav-icon {
      display: none;
    }
    @media (max-width: 1024px) {
      .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 15vh;
        left: -110%;
        opacity: 1;
        background: rgba($color: #000000, $alpha: 0.5);
      }
      .nav-menu.active {
        left: 0px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 50;
      }
      .nav-item .active {
        color: #ff8a00;
        border: none;
      }
      .nav-links {
        padding: 1.5rem;
        width: 100%;
        display: table;
      }
      .nav-icon {
        display: block;
        cursor: pointer;
      }
    }
  }
  svg {
    display: block;
  }

  svg {
    fill: #fff;
  }
  svg:hover {
    fill: #ff8a00;
  }

  .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .icon.size-auto svg {
    width: 100%;
    height: 100%;
  }

  .icon svg {
    width: 1rem;
    height: 1rem;
  }

  .icon.md svg {
    width: 2rem;
    height: 2rem;
    @include media('<=1300px') {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
