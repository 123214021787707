@import '../../breakpoint.scss';
.overviewInfo {
  width: 100vw;
  height: 100vh;
  position: relative;
  color: #f2f2f2;
  font-family: 'Philosopher', sans-serif;

  .innerOverviewContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-left: 6rem;
    padding-bottom: 10rem;
    
    @include media('<=1700px') {
      padding-left: 2rem;
      padding-bottom: 7rem;
    }
    @include media('<=tablet') {
      align-items: center;
      padding: 0 3rem;
    }
    @include media('<=phone') {
      align-items: center;
      padding: 0 1.5rem;
    }
    .infoContainer {
      padding-left: 6rem;
      width: 75%;
      @include media('<=SMdesktop') {
        padding-left: 1.5rem;
      }
      @include media('<=tablet') {
        padding: 1rem;
        width: 100%;
      }
      .overviewTitle {
        padding-bottom: 1rem;
        h1 {
          font-size: 2.5rem;
          line-height: 2.5rem;
          background: -webkit-linear-gradient(180deg, #ffc700 0%, #ff8a00 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        @include media('<=tablet') {
          padding-bottom: 0.2rem;
          h1 {
            font-size: 2rem;
            line-height: 2rem;
          }
        }
      }
      .overviewDesc {
        h3 {
          font-style: normal;
          font-weight: normal;
          font-size: 1.75rem;
          color: #f2f2f2;
          padding-top: 0.7rem;
          @include media('<=1700px') {
            font-size: 1.4rem;
          }
          @include media('<=1450px') {
            font-size: 1.1rem;
            line-height: 1.1rem;
          }
        }
        @include media('<=tablet') {
          h3 {
            font-size: 1rem;
            line-height: 1rem;
          }
        }
      }
    }
  }
}
